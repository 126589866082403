window.addEventListener('heyflow-screen-view', async (event) => {
    // console.log('heyflow screen view:', event.detail);
    if(event.detail.screenIndex === 0) {
        var ip = (await fetch("https://api.ipify.org/?format=json").then(r => r.json())).ip;
        document.querySelector('[data-variable="ip_address"]').value = ip;
    
        var userAgent = window.navigator.userAgent;
        document.querySelector('[data-variable="user_agent"]').value = userAgent;
    }
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});